<template>
    <div @click="handleClose" style="height:100%" class="bizParam">
      <a-breadcrumb class="breadcrumb">
          <a-breadcrumb-item>首页</a-breadcrumb-item>
          <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
          <a-breadcrumb-item>待办事项</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="main-box bizParam-box">
        <div style="width:200px;height:600px;overflow: auto;">
          <a-menu :defaultSelectedKeys="['1']" @click="handleClick">
            <a-menu-item key="1">
              <a-row>
                <a-col :span="16">
                  今天需回访客户
                </a-col>
                <a-col :span="2" :offset="4">
                  <a-badge :number-style="{ backgroundColor: '#ed5565'}" :count="counts.today_count" />
                </a-col>
              </a-row>
            </a-menu-item>

            <a-menu-item key="6">
              <a-row>
                <a-col :span="16">
                  今天需回访线索
                </a-col>
                <a-col :span="2" :offset="4">
                  <a-badge :number-style="{ backgroundColor: '#ed5565'}" :count="counts.today_leads_count" />
                </a-col>
              </a-row>
            </a-menu-item>

            <a-menu-item key="2">
              <a-row>
                <a-col :span="16">
                  新分给我的线索
                </a-col>
                <a-col :span="2" :offset="4">
                  <a-badge :number-style="{ backgroundColor: '#ed5565'}" :count="counts.leads_count" />
                </a-col>
              </a-row>
            </a-menu-item>
            <a-menu-item key="3">
              <a-row>
                <a-col :span="16">
                  新分给我的客户
                </a-col>
                <a-col :span="2" :offset="4">
                  <a-badge :number-style="{ backgroundColor: '#ed5565'}" :count="counts.customer_count" />
                </a-col>
              </a-row>
            </a-menu-item>
            <a-menu-item key="4">
              <a-row>
                <a-col :span="16">
                  待进入公海的客户
                </a-col>
                <a-col :span="2" :offset="4">
                  <a-badge :number-style="{ backgroundColor: '#ed5565'}" :count="counts.remind_customer_count" />
                </a-col>
              </a-row>
            </a-menu-item>
            <a-menu-item key="5">
              <a-row>
                <a-col :span="16">
                  即将到期的合同
                </a-col>
                <a-col :span="2" :offset="4">
                  <a-badge :number-style="{ backgroundColor: '#ed5565'}" :count="counts.remind_contract_count" />
                </a-col>
              </a-row>
            </a-menu-item>
          </a-menu>
        </div>
        <div :style="{ width:wWidth,paddingLeft:'25px'}">
            <contactCustomer :close='close' v-if="keyPath[0] === '1'" />
            <contactLead :close='close' v-if="keyPath[0] === '6'" />
            <myClue :close='close' v-if="keyPath[0] === '2'" />
            <myCustomer :close='close' v-if="keyPath[0] === '3'" />
            <seasCustomer :close='close' v-if="keyPath[0] === '4'" />
            <expiringContract v-if="keyPath[0] === '5'" />
        </div>
      </div>
    </div>
</template>
<script>
import myClue from './modal/myClue'
import myCustomer from './modal/myCustomer'
import seasCustomer from './modal/seasCustomer'
import expiringContract from './modal/expiringContract'
import contactCustomer from './modal/contactCustomer'
import contactLead from './modal/contactLead'
export default {
    name:'schedule',
    provide() {
        return {
            parent: this
        }
    },
    data(){
        return{
            tabPosition: 'left',
            keyPath:['1'],
            close:false,
            myWidth:'',
        }
    },
    components:{
        myClue,
        myCustomer,
        seasCustomer,
        expiringContract,
        contactCustomer,
        contactLead,
    },
    computed: {
      counts() {
        return this.$store.state.loading.count
      },
      wWidth(){
          return `${Number(this.myWidth) - 480}px`
      }
    },
    async created(){
      this.myWidth = this.windowHeight()
      // let res = await this.$store.dispatch('todoCountAction', {})
      // await this.$store.dispatch('setCount', res.data)
    },
    methods:{
        handleClick(e) {
            this.keyPath = e.keyPath
        },
        handleClose(){
          this.close = !this.close
        },
        windowHeight() {
            return document.body.offsetWidth
        },
    },
}
</script>
<style lang="scss">
  .bizParam{
    .main-box{
      .ant-tabs-tab{
        text-align: left;
      }
      .ant-menu-light{
        border-right: 2px solid #e8e8e8;
      }
      .ant-menu-item:hover{
        background-color: #e6fff6;
      }
    }
    .bizParam-box{
      display: flex;
    }
    .ant-menu-item-selected{
      box-shadow: 10px 0px 0px #00cca2
    }
  }
  .rowActive{
    background:#e6fff6
  }
</style>