<template>
    <div @click="hideFollowModal" style="height:100%" class="zcxy zl-list">
        <div class="title flts-title">
            <h3>今天需联系客户</h3>
        </div>
        <div class="clearfix table-tools">
            <div class="search">
              <a-form layout='inline'>
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                        <a-select-option :value="0">我的</a-select-option>
                        <a-select-option :value="1">我下属的</a-select-option>
                    </a-select>
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <a-select :value='searchParams.search.contact_status' @change='handleContact' style="width: 140px">
                        <a-select-option :value="0">全部</a-select-option>
                        <a-select-option :value="1">今天需联系</a-select-option>
                        <a-select-option :value="2">已逾期</a-select-option>
                    </a-select>
                    </a-tooltip>
                </a-form-item>
                
                <a-form-item>
                    <a-button id="customerBtn" type="primary" :loading="loading" @click="searchList">搜索</a-button>
                </a-form-item>
              </a-form>
            </div>
        </div>
        <div @click.stop class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="middle" :pagination="false" :bordered='false' :scroll="{ x: 1400 }"
                :columns="columns" :dataSource="list" rowKey='task_id' :customRow="clickRow" :rowClassName="rowClassName" @change="handleChange">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>

                    <template slot="name" slot-scope="text, record">
                        <div style="display:flex;">
                            <a style="max-width:90px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">{{ text }}</a>
                            <!-- <svg style="width: 16px;height: 16px;margin-left: 6px;" aria-hidden="true">
                                <use v-show="record.gender == 2" xlink:href="#icon-sex_woman"></use>
                                <use v-show="record.gender == 1" xlink:href="#icon-sex_man"></use>
                            </svg> -->
                         </div>
                    </template>
                    <template slot="time" slot-scope="text">
                         <!-- <span v-if="text" v-html="timeHandle(moment(text).diff(moment(),'hour'))"></span> -->
                         <span v-if="text">
                             <a-badge :color="timeHandle(moment(text).diff(moment(),'hour')).color" :text="timeHandle(moment(text).diff(moment(),'hour')).text" />
                         </span>
                         <span v-else>--</span>
                    </template>
                    <!-- <template slot="action" slot-scope="text,record">
                        <a href="javascript:;" @click="showFollowModal($event, record)">跟进</a>
                    </template> -->
            </a-table>
        </div>
        <div class="page">
            <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div>
        <followModal :authType="authType" :item="modalData" :leadsId='leadsId' isTab='1' v-if="followVisible"/>
    </div>
</template>
<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'task_id',fixed: 'left'},
  {title: '客户名称', width:'100px', dataIndex: 'customer_name', key: '1' ,fixed: 'left',scopedSlots: { customRender: 'name' },ellipsis: true},
  {title: '性别', width:'45px', dataIndex: 'gender', key: 'gender',align:"center"},
  {title: '年龄', width:'45px', dataIndex: 'year', key: 'year',align:"center"},
  {title: '联系电话', width:'120px',dataIndex: 'cellphone', key: '3'},
  {title: '最后跟进', dataIndex: 'recent_contact', key: 'recent_contact' ,sorter:()=>{}},   
  {title: '回访时间', dataIndex: 'next_time', key: 'next_time',sorter:()=>{},scopedSlots: { customRender: 'time' }},
  {title: '来源渠道', dataIndex: 'channel_id',ellipsis: true, key: '2' ,sorter:()=>{}},
  {title: '归属校区', dataIndex: 'studio_id', key: 'studio_id',ellipsis: true ,sorter:()=>{}},
  {title: '负责人', dataIndex: 'course_consultant_id', key: 'course_consultant_id' ,sorter:()=>{}}, 
//   {title: '更新时间', dataIndex: 'update_time', key: 'update_time' ,sorter:()=>{}},
  {title: '添加人', dataIndex: 'created_by', key: 'created_by' ,sorter:()=>{}},
  {title: '备注', dataIndex: 'remark', key: '9' ,ellipsis: true},
  {title: '添加时间', dataIndex: 'created_at', key: 'created_at' ,sorter:()=>{}}
//   { title: '操作', key: 'operation', fixed: 'right', width:80, scopedSlots: { customRender: 'action' }}
]

import moment from 'moment'
import tableMixins from '@/common/mixins/table'
import authority from '@/common/mixins/authority'
import { timeHandle } from '@/utils/timeFormat'
import followModal from '@/views/clientresource/modal/followModal/index'

export default {
    name:'contactCustomer',
    provide() {
        return {
            parent: this
        }
    },
    props:['close'],
    data(){
        return{
            loading: false,
            list:[],
            pageSizeOptions: ['10', '20', '30', '40', '100'],
            current: 1,
            columns,
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            searchParams: {
                "page": 1,
                "per-page": 10,
                "search": {
                    contact_status:0,
                    is_sub:0
                },
                "sort": ''
            },
            modalData: {},
            followVisible:false,
            leadsId:'',
            clickId:'',
            authType:['crm','todo']
        }

    },
    mixins: [ tableMixins , authority ],
    components:{followModal},
    beforeCreate () {
        if(!this.$ls.get('is_sub')){
            this.$ls.set('is_sub', 0)
        }
    },
    methods:{
        moment,
        timeHandle,
        async getList() {
            this.loading = true
            this.searchParams.search.is_sub = this.$ls.get('is_sub')
            let res = await this.$store.dispatch('todoTodayAction', this.searchParams)
            this.list = res.items
            this.pageParams = res._meta
            this.loading = false
        },
        clickRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.clickId = record.customer_id
                        this.showFollowModal(null, record)
                    }
                }
            }
        },
        rowClassName(record, index) {
            let className = "";
            if (record.customer_id == this.clickId) {
                className = "rowActive";
                this.$emit('change', this.clickId)
            }
            return className;
        },
        showFollowModal(e, item) {
            e && e.stopPropagation()
            this.modalData = item
            this.leadsId = item.customer_id
            this.followVisible = true
            return 
        },
        hideFollowModal(type) {
            if (type === 1) {
                this.getList()
            }
            this.followVisible = false
        },
        handleContact(e){
            this.searchParams.search.contact_status = e
        },
        handleIsSub(e){
            this.$ls.set('is_sub', e)
            this.searchParams.search.is_sub = e
        },
        handleChange(pagination, filters, sorter){
            if(sorter.order){
                if(sorter.order === "ascend"){
                    this.searchParams['sort'] = `${sorter.field}`
                }else{
                    this.searchParams['sort'] = `-${sorter.field}`
                }
                }else{
                this.searchParams['sort'] = ''
                }
            this.getList();
        },
    },
    watch: {
        close(newValue, oldValue) {
            this.hideFollowModal()
        }
    },
}
</script>
