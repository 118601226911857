<template>
    <div class="zcxy zl-list">
        <div class="title flts-title">
            <h3>即将到期的合同</h3>
        </div>
        <div class="clearfix table-tools">
            <div class="search">
              <a-form layout='inline'>

                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                        <a-select-option :value="0">我的</a-select-option>
                        <a-select-option :value="1">我下属的</a-select-option>
                    </a-select>
                    </a-tooltip>
                </a-form-item>

                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <a-select :value='searchParams.search.contract_status' @change='handleContact' style="width: 140px">
                        <a-select-option :value="1">即将到期</a-select-option>
                        <a-select-option :value="0">已到期</a-select-option>
                    </a-select>
                    </a-tooltip>
                </a-form-item>
                
                <a-form-item>
                    <a-button type="primary" :loading="loading" @click="searchList">搜索</a-button>
                </a-form-item>
              </a-form>
            </div>
        </div>
        <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="middle" :pagination="false" :bordered='false' rowKey="contract_id"
                :columns="columns" :dataSource="list" @change="handleChange" :scroll="{ x: 1100 }">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>

                    <template slot="name" slot-scope="text, record">
                        <div style="display:flex;">
                            <a style="max-width:90px;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">{{ text }}</a>
                            <!-- <svg style="width: 16px;height: 16px;margin-left: 6px;" aria-hidden="true">
                                <use v-show="record.gender == 2" xlink:href="#icon-sex_woman"></use>
                                <use v-show="record.gender == 1" xlink:href="#icon-sex_man"></use>
                            </svg> -->
                        </div>
                    </template>
            </a-table>
        </div>
        <div class="page">
            <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                </template>
            </a-pagination>
        </div>
    </div>
</template>
<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width:'100px', dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'studentName' } ,ellipsis: true},
  { title: '签约合同号', width:'180px', dataIndex: 'contract_no', key: '1', scopedSlots: { customRender: 'out_trade_no' } , fixed: 'left'},
  { title: '签约校区', width:'120px',dataIndex: 'studio_id', key: '3' ,ellipsis: true,sorter:()=>{}},
  { title: '课程科目', width:'100px',dataIndex: 'course_cate_id', key: '5' ,ellipsis: true,sorter:()=>{}},
  { title: '剩余课时', width:'100px',dataIndex: 'left_times', key: '8' ,align:'center',sorter:()=>{}},
  { title: '有效期至', dataIndex: 'contract_end_date', key: 'contract_end_date' ,align:'center',sorter:()=>{}},
  { title: '课程花费', width:'100px',dataIndex: 'contract_costs', key: '10' ,align:'center'},
  { title: '合同状态', width:'100px',dataIndex: 'contract_status', key: 'contract_status' ,align:'center'},
  { title: '课程顾问', width:'100px',dataIndex: 'course_consultant', key: 'course_consultant' ,ellipsis: true,sorter:()=>{}},
  { title: '经办人', width:'100px',dataIndex: 'created_by', key: '14' ,align:'center'},
  { title: '操作时间', dataIndex: 'created_at', key: '15' ,align:'center'}
]

export default {
    name:'expiringContract',
    data(){
        return{
            loading: false,
            list:[],
            pageSizeOptions: ['10', '20', '30', '40', '100'],
            current: 1,
            columns,
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            searchParams: {
                "page": 1,
                "per-page": 10,
                "search": {
                    contract_status:1,
                    is_sub:0
                },
                "sort": ''
            },
        }
    },
    created () {
        this.pageParams.perPage = this.$ls.get('perPage') || 10
        this.searchParams['per-page'] = this.pageParams.perPage
        this.getList()
    },
    beforeCreate () {
        if(!this.$ls.get('is_sub')){
            this.$ls.set('is_sub', 0)
        }
    },
    methods:{
        async getList() {
            this.loading = true
            this.searchParams.search.is_sub = this.$ls.get('is_sub')
            let res = await this.$store.dispatch('todoContractAction',this.searchParams)
            this.list = res.items
            this.pageParams = res._meta
            this.loading = false
        },
        changePage(page, pageSize) {
            this.searchParams.page = page
            this.getList()
        },
        onShowSizeChange(current, size) {
            this.searchParams['per-page'] = size
            this.$ls.set('perPage',size)
            this.getList()
        },
        searchList() {
            this.searchParams.page = 1
            this.getList()
        },
        handleContact(e){
            this.searchParams.search.contract_status = e
        },
        handleChange(pagination, filters, sorter){
            if(sorter.order){
                if(sorter.order === "ascend"){
                    this.searchParams['sort'] = `${sorter.field}`
                }else{
                    this.searchParams['sort'] = `-${sorter.field}`
                }
                }else{
                this.searchParams['sort'] = ''
                }
            this.getList();
        },
        handleIsSub(e){
            this.$ls.set('is_sub', e)
            this.searchParams.search.is_sub = e
        }
    }
}
</script>
